import { Fragment, createRef, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import CircularProgress from "@mui/material/CircularProgress";

import { SignAuthorizationStyles } from "./index.css";
import VideoCapture from "./molecules/videoCapture";
import { to, verifiVariable } from "../../../utils/enums";
import {
    clearForm,
    clearResClvUnica,
    saveTramitANFInfo,
    uploadVideo,
} from "../../../store/formANFTramit";
import { useScreenshot } from "use-react-screenshot";
import ErrorAlert from "./molecules/errorAlert";
import BrowserNameHook from "../../../hooks/BrowserNameHook";
import ModalContractUploadError from "../../modalContractUploadError";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    color: theme.palette.text.secondary,
    boxShadow: "unset",
}));

const CustomButton = styled(LoadingButton)((props) => ({
    width: "190px",
    marginTop: "-180px",
    fontStyle: "normal",
    boxShadow: "none",
    fontWeight: "bolder",
    textTransform: "none",
    fontSize: "0.80rem",
    padding: "11px 28px",
    border: "1px solid",
    borderRadius: "7px",
    lineHeight: 1.5,
    backgroundColor: props.theme.palette.primary.light,
    borderColor: props.theme.palette.primary.light,
    alignSelf: "center",
    fontFamily: ['"PoppinsBold"'].join(","),
    "&:hover": {
        backgroundColor: props.theme.palette.primary.xlight,
        borderColor: props.theme.palette.primary.xlight,
        boxShadow: "none",
    },
    "&:active": {
        boxShadow: "none",
        backgroundColor: "#0062cc",
        borderColor: "#005cbf",
    },
    "&:focus": {
        boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
    },
}));

const SignAuthorization = () => {
    const navegador = BrowserNameHook();
    const ref = createRef(null);
    const styles = SignAuthorizationStyles();
    const {
        formANFTramit: {
            detailContract: { contrato: detailContract, firmantes },
            restClvUnica,
            dniInfo,
            token,
            screenshot,
            status,
            loading,
            message,
            uploadProgress,
        },
    } = useSelector((state) => state.root);
    const Ref = useRef(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loadingVideo, setLoadingVideo] = useState(false);
    const [textLoading, setTextLoading] = useState("Siguiente");
    const [videoBase64, setVideoBase64] = useState(null);
    const [image, takeScreenshot] = useScreenshot();
    const [errors, setErrors] = useState(0);
    const [titleErrors, setTitleErrors] = useState("");
    const [msnErrors, setMsnErrors] = useState("");
    const [ipClient, setIpClient] = useState(null);
    const [timer, setTimer] = useState("00:01:20");
    const [otherSigners, setOtherSigners] = useState(null);
    const [signer, setSigner] = useState(null);
    const [contrato, setContrato] = useState(null);
    const [openModalErrors, setOpenModalErrors] = useState(false);
    const [cameraGranted, setCameraGranted] = useState(false);
    const [microGranted, setMicroGranted] = useState(false);
    const [errorsUpload, setErrorsUpload] = useState([]);

    const getImage = () => takeScreenshot(ref.current);

    const subirVideo = () => {
        const regex = /safari/i;
        const { contractID, signerID } = restClvUnica;

        const data = {
            contractID: contractID,
            signerID: signerID,
            ipAddress: ipClient,
            screenshot: screenshot.replace("data:image/png;base64,", ""),
            imgIdentityFront: dniInfo[0].replace("data:image/png;base64,", ""),
            imgIdentityBack: dniInfo[1].replace("data:image/png;base64,", ""),
            file: videoBase64.replace(
                `data:video/${regex.test(navegador) ? "mp4" : "webm"};base64,`,
                ""
            ),
        };

        dispatch(uploadVideo(token, data));
    };

    const getIP = () => {
        return new Promise((resolve, reject) => {
            const ipScript = document.createElement("script");
            ipScript.src =
                "https://api.ipify.org?format=jsonp&callback=getIPCallback";

            // Callback para obtener la dirección IP
            window.getIPCallback = (ipData) => {
                delete window.getIPCallback;
                document.body.removeChild(ipScript);

                resolve(ipData.ip);
            };

            // Agregar el script al documento
            document.body.appendChild(ipScript);
        });
    };

    const getTimeRemaining = (e) => {
        const total = Date.parse(e) - Date.parse(new Date());
        const seconds = Math.floor((total / 1000) % 60);
        const minutes = Math.floor((total / 1000 / 60) % 60);
        const hours = Math.floor((total / 1000 / 60 / 60) % 24);
        return {
            total,
            hours,
            minutes,
            seconds,
        };
    };

    const startTimer = (e) => {
        let { total, hours, minutes, seconds } = getTimeRemaining(e);
        if (total >= 0) {
            // update the timer
            // check if less than 10 then we need to
            // add '0' at the beginning of the variable
            setTimer(
                (hours > 9 ? hours : "0" + hours) +
                ":" +
                (minutes > 9 ? minutes : "0" + minutes) +
                ":" +
                (seconds > 9 ? seconds : "0" + seconds)
            );
        }
    };

    const clearTimer = (e) => {
        // If you adjust it you should also need to
        // adjust the Endtime formula we are about
        // to code next
        setTimer("00:01:20");

        // If you try to remove this line the
        // updating of timer Variable will be
        // after 1000ms or 1sec
        if (Ref.current) clearInterval(Ref.current);
        const id = setInterval(() => {
            startTimer(e);
        }, 1000);
        Ref.current = id;
    };

    const getDeadTime = () => {
        let deadline = new Date();

        // This is where you need to adjust if
        // you entend to add more time
        deadline.setSeconds(deadline.getSeconds() + 120);
        return deadline;
    };

    useEffect(() => {

        let permissionStatusCamara, permissionStatusMicro;

        const errorText = ["Advertencia de permisos.", "Dar permisos de acceso a la camara y/o microfono."];

        (async () => {

            permissionStatusCamara = await navigator.permissions.query({ name: 'camera' });

            permissionStatusMicro = await navigator.permissions.query({ name: 'microphone' });

            if (permissionStatusCamara['state'] === 'denied' || permissionStatusMicro.state === 'denied') {
                setCameraGranted(false);
                setMicroGranted(false);
                setErrorsUpload(errorText);
                setOpenModalErrors(true);
            }

            if (permissionStatusCamara['state'] !== 'denied') setCameraGranted(true);

            if (permissionStatusMicro['state'] !== 'denied') setMicroGranted(true);

            permissionStatusCamara.onchange = (_this, event) => {

                if (_this.target.state !== 'denied') setCameraGranted(true);

                if (_this.target.state === 'denied') {
                    setCameraGranted(false);
                    setErrorsUpload(["Advertencia de permisos.", "Dar permisos de acceso a la camara."]);
                    setOpenModalErrors(true);
                }
            };

            permissionStatusMicro.onchange = (_this, event) => {

                if (_this.target.state !== 'denied') setMicroGranted(true);

                if (_this.target.state === 'denied') {
                    setMicroGranted(false);
                    setErrorsUpload(["Advertencia de permisos.", "Dar permisos de acceso al microfono."]);
                    setOpenModalErrors(true);
                }

            };

        })();

        return () => {
            permissionStatusCamara.onchange = null;
            permissionStatusMicro.onchange = null;
        }

    }, []);

    useEffect(() => {
        if (loading) {
            setTextLoading("Guardando datos");
        } else {
            setTextLoading("Siguiente");
        }

        if (status === "fail") {
            setErrors((e) => e + 1);
            setTitleErrors("Ah ourrido un error.");
            setMsnErrors([message]);
        }

        if (status === "success") {
            navigate(to.SIGN_AUTH_VERIFIED_IDENTITY);

            dispatch(clearResClvUnica());
            dispatch(clearForm());
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status, loading]);

    useEffect(() => {
        if (!verifiVariable(image)) {
            dispatch(
                saveTramitANFInfo({
                    indexe: "screenshot",
                    value: image,
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [image]);

    useEffect(() => {
        (async () => {
            try {
                const ipClient = await getIP();
                setIpClient(ipClient);
            } catch (error) {
                console.log("error: ", error);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!verifiVariable(uploadProgress) && uploadProgress === 100)
            clearTimer(getDeadTime());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uploadProgress]);

    useEffect(() => {
        if (timer.replace("00:", "") === "00:00")
            navigate(to.SIGN_AUTH_ERROR + "/400");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timer]);

    useEffect(() => {
        if (!verifiVariable(restClvUnica)) {
            const { signerID } = restClvUnica;

            setContrato(detailContract);

            const otherSigners = firmantes
                .filter(
                    (item) =>
                        item["signerID"] !== signerID &&
                        [item["signerLegal"], item["authNeed"]].includes("1")
                )
                .map((item) => item["full_name"]);

            setSigner(firmantes.find((item) => item["signerID"] === signerID));

            if (otherSigners.length !== 0) {
                if (otherSigners.length > 1)
                    setOtherSigners(otherSigners.slice(0, -1).join(", ") + " y " + otherSigners.slice(-1));

                if (otherSigners.length === 1) setOtherSigners(otherSigners[0]);
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [restClvUnica]); // SGN659c97baca05a

    return (
        <Box>
            <Grid container>
                <Grid item xs={12}>

                    <ModalContractUploadError
                        openModalErrors={openModalErrors}
                        setOpenModalErrors={setOpenModalErrors}
                        data={errorsUpload}
                        modalIcon={'warnIcon'}
                    />

                    <Item className={styles.itemContent}>
                        <ErrorAlert
                            errors={errors}
                            msn={msnErrors}
                            title={titleErrors}
                        />

                        <Typography
                            className={styles.mainTitle}
                            variant="h5"
                            gutterBottom
                        >
                            Por favor, verifique su identidad
                        </Typography>

                        <Divider variant="fullWidth" />

                        <Typography
                            className={styles.infoText}
                            variant="subtitle1"
                            gutterBottom
                        >
                            Deberás grabarte en un video mencionando lo
                            siguiente:{" "}
                            <span>
                                {" "}
                                “Con plena voluntad, yo, [
                                {!verifiVariable(contrato) &&
                                    signer["full_name"]}{" "}
                                + debes mostrar su Identificación], firmo el
                                contrato{" "}
                                {!verifiVariable(contrato) &&
                                    contrato["typeContract"]}{" "}
                                {!verifiVariable(otherSigners) &&
                                    `junto con [${otherSigners}]`}
                                ”.
                            </span>
                        </Typography>

                        <Typography
                            className={styles.warningText}
                            variant="subtitle1"
                            gutterBottom
                        >
                            IMPORTANTE: DEBES MOSTRAR TU DOCUMENTO DE IDENTIFICACIÓN DURANTE LA GRABACIÓN DEL VIDEO Y ESTE DEBE ESTAR VIGENTE.
                        </Typography>

                        {loading && (
                            <Fragment>
                                <Typography
                                    className={`${styles.infoText} ${styles.loadinVideoText}`}
                                    variant="subtitle1"
                                    gutterBottom
                                >
                                    <span>
                                        Se está realizando la verificación. Esto
                                        puede tardar un par de minutos, por
                                        favor espere y no cierre esta ventana.
                                    </span>
                                </Typography>

                                <Box
                                    sx={{
                                        position: "relative",
                                        display: "inline-flex",
                                    }}
                                >
                                    <CircularProgress size={100} />
                                    <Box
                                        sx={{
                                            top: 0,
                                            left: 0,
                                            bottom: 0,
                                            right: 0,
                                            position: "absolute",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            "& div.MuiTypography-caption": {
                                                fontSize: "1.4rem",
                                            },
                                        }}
                                    >
                                        <Typography
                                            variant="caption"
                                            component="div"
                                            color="text.secondary"
                                        >
                                            {`${uploadProgress}%`}
                                        </Typography>
                                    </Box>
                                </Box>

                                {!verifiVariable(uploadProgress) &&
                                    uploadProgress === 100 && (
                                        <Fragment>
                                            <br />
                                            <Typography
                                                className={`${styles.infoText} ${styles.loadinVideoText}`}
                                                variant="subtitle1"
                                                gutterBottom
                                                textAlign={"justify"}
                                            >
                                                <span>
                                                    Si en{" "}
                                                    {timer.replace("00:", "")}s
                                                    no visualiza la carga del
                                                    proceso exitoso, deberá
                                                    solicitar al administrador
                                                    un nuevo enlace de
                                                    validación de identidad y
                                                    tendrá que realizar el
                                                    proceso nuevamente.
                                                </span>
                                            </Typography>{" "}
                                        </Fragment>
                                    )}
                            </Fragment>
                        )}

                        {!loading && ![cameraGranted, microGranted].includes(false) && (
                            <Fragment>
                                <div ref={ref}>
                                    <VideoCapture
                                        caprute={getImage}
                                        loadingVideo={(e) => {
                                            setLoadingVideo(e);

                                            if (e) {
                                                setTextLoading(
                                                    "Cargando video"
                                                );
                                            } else {
                                                setTextLoading("Siguiente");
                                            }
                                        }}
                                        videoBase64={(video) =>
                                            setVideoBase64(video)
                                        }
                                        openCamera={true}
                                    />
                                </div>
                                <CustomButton
                                    disabled={[
                                        verifiVariable(videoBase64),
                                        loading,
                                    ].includes(true)}
                                    loading={loadingVideo}
                                    onClick={() => subirVideo()}
                                    variant="contained"
                                    color="primary"
                                >
                                    {textLoading}
                                </CustomButton>
                            </Fragment>
                        )}
                    </Item>
                </Grid>
            </Grid>
        </Box>
    );
};

export default SignAuthorization;
